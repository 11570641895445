.flex-row {
  display: flex !important;
  flex-direction: row !important;
}

.flex-column {
  display: flex !important;
  flex-direction: column !important;
}

.flex-center {
  justify-content: center !important;
  align-items: center !important;
}

.flex-start {
  justify-content: flex-start !important;
}

.flex-end {
  justify-content: flex-end !important;
}

.flex-between {
  justify-content: space-between;
}
