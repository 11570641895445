.ant-pro-basicLayout-content {
  margin: 10px;

  .ant-pro-page-container {
    margin: 0;

    .ant-pro-page-container-children-content {
      margin: 10px 0;
    }
  }
}
// Table cell padding
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 5px 10px;
}

.ant-table-content {
  @media @tablet {
    overflow: auto;
  }
}

.ant-table {
  table {
    table-layout: fixed;

    @media @tablet {
      table-layout: auto;
    }
  }
}

.ant-input-number {
  width: 100% !important;
}
