.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  margin-right: 5px !important;

  .ant-spin-dot {
    vertical-align: middle;
  }
}
