@import 'styles/responsive.less';

.container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #001529;
}

.content {
  width: 450px;

  @media @mobile {
    width: 90%;
  }
}
