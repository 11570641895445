.dropdown {
  padding: 8px;
}

.profile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.names {
  margin-left: 6px;
}

.item {
  padding: 8px 16px;
  min-width: 200px;
}
