.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.pointer {
  cursor: pointer;
}

.noselect {
  user-select: none;
}
